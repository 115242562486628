import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PersistGate } from 'redux-persist/integration/react';
import LoginComponent from './components/Login';
import EncryptionComponent from './components/JasyptEncryption';
import NavigationBarComponent from './components/NavigationBar';

const PGate = PersistGate as any;

const persistConfig = {
    key: 'root',
    storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});

const persistor = persistStore(store);

const useAuthentication = () => {
    const initialIsAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
    const initialIdTokenString = localStorage.getItem('idTokenJwt');
    const initialIdToken = initialIdTokenString ? initialIdTokenString : null;
    const initialAccessTokenString = localStorage.getItem('accessTokenJwt');
    const initialAccessToken = initialAccessTokenString ? initialAccessTokenString : null;
    const initialRefreshTokenString = localStorage.getItem('refreshTokenJwt');
    const initialRefreshToken = initialRefreshTokenString ? initialRefreshTokenString : null;

    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(initialIsAuthenticated);
    const [idTokenJwt, setIdTokenJwt] = useState<string | null>(initialIdToken);
    const [accessTokenJwt, setAccessTokenJwt] = useState<string | null>(initialAccessToken);
    const [refreshTokenJwt, setRefreshTokenJwt] = useState<string | null>(initialRefreshToken);

    useEffect(() => {
        if (idTokenJwt && accessTokenJwt && refreshTokenJwt) {
            setIsAuthenticated(true);

            const idTokenJwtString = idTokenJwt;
            const accessTokenJwtString = accessTokenJwt;
            const refreshTokenJwtString = refreshTokenJwt;
            console.log(idTokenJwt);

            localStorage.setItem('isAuthenticated', 'true');
            localStorage.setItem('idTokenJwt', idTokenJwtString);
            localStorage.setItem('accessTokenJwt', accessTokenJwtString);
            localStorage.setItem('refreshTokenJwt', refreshTokenJwtString);
        }
    }, [idTokenJwt, accessTokenJwt, refreshTokenJwt]);

    return {
        isAuthenticated,
        setIsAuthenticated,
        setIdTokenJwt,
        setAccessTokenJwt,
        setRefreshTokenJwt,
    };
};

const App: React.FC = () => {
    const {
        isAuthenticated,
        setIsAuthenticated,
        setIdTokenJwt,
        setAccessTokenJwt,
        setRefreshTokenJwt,
    } = useAuthentication();

    const handleLogout = () => {
        // Clear authentication data and update the state
        localStorage.clear();
        setIsAuthenticated(false);
        setIdTokenJwt(null);
        setAccessTokenJwt(null);
        setRefreshTokenJwt(null);
    };

    return (
        <Provider store={store}>
            <PGate loading={null} persistor={persistor}>
                <Router>
                    <div className="App">
                        <header className="Reactive Util">
                            {isAuthenticated && <NavigationBarComponent handleLogout={handleLogout} />}
                            <Routes>
                                <Route
                                    path="/oauth2/callback/google"
                                    element={
                                        isAuthenticated ? (
                                            <EncryptionComponent />
                                        ) : (
                                            <LoginComponent
                                                setIdTokenJwt={setIdTokenJwt}
                                                setAccessTokenJwt={setAccessTokenJwt}
                                                setRefreshTokenJwt={setRefreshTokenJwt}
                                            />
                                        )
                                    }
                                />
                                <Route
                                    path="/"
                                    element={
                                        isAuthenticated ? (
                                            <EncryptionComponent />
                                        ) : (
                                            <LoginComponent
                                                setIdTokenJwt={setIdTokenJwt}
                                                setAccessTokenJwt={setAccessTokenJwt}
                                                setRefreshTokenJwt={setRefreshTokenJwt}
                                            />
                                        )
                                    }
                                />
                            </Routes>
                        </header>
                    </div>
                </Router>
            </PGate>
        </Provider>
    );
};

export default App;
import React, {useState} from 'react';
import {JasyptDecryptApi, JasyptDecryptRequest, JasyptEncryptApi, JasyptEncryptRequest} from '../apis';
import '../css/JasyptEncryption.css';
import {Configuration} from "../apis/runtime";

const JasyptEncryption = () => {
    const [encryptedValue, setEncryptedValue] = useState('');
    const [decryptedValue, setDecryptedValue] = useState('');
    const [value, setValue] = useState('');
    const [secret, setSecret] = useState('');
    const [encryptSecretError, setEncryptSecretError] = useState(false);
    const [decryptSecretError, setDecryptSecretError] = useState(false);

    const accessToken = localStorage.getItem('accessTokenJwt');
    if (accessToken) {

    } else {
        console.error('Access token not found');
    }

    const jasyptApiConfiguration = new Configuration({
        basePath: "https://localhost:8443",
        accessToken: accessToken ?? undefined,
    });

    const jasyptEncryptApi = new JasyptEncryptApi(jasyptApiConfiguration);
    const jasyptDecryptApi = new JasyptDecryptApi(jasyptApiConfiguration);

    const handleEncrypt = () => {
        if (secret.length < 8) {
            setEncryptSecretError(true);
            return;
        }

        const jasyptEncryptRequest: JasyptEncryptRequest = {
            encryptRequest: {
                value: value,
                secret: secret,
            },
        };

        jasyptEncryptApi
            .jasyptEncrypt(jasyptEncryptRequest)
            .then((response) => {
                // Handle successful response
                setEncryptedValue(response.value);
            })
            .catch((error) => {
                // Handle error
                console.error('Encryption error:', error);
            });
    };

    const handleDecrypt = () => {
        if (secret.length < 8) {
            setDecryptSecretError(true);
            return;
        }

        const jasyptDecryptRequest: JasyptDecryptRequest = {
            decryptRequest: {
                value: encryptedValue,
                secret: secret,
            },
        };

        jasyptDecryptApi
            .jasyptDecrypt(jasyptDecryptRequest)
            .then((response) => {
                // Handle successful response
                setDecryptedValue(response.value);
            })
            .catch((error) => {
                // Handle error
                console.error('Decryption error:', error);
            });
    };

    return (
        <div className="jasypt-encryption-container">
            <h1 className="jasypt-header">Jasypt Encryption and Decryption</h1>
            <div className="jasypt-encryption">
                {/* Encryption UI */}
                <div className="input-container">
                    <textarea
                        className="input-field"
                        placeholder="Value"
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                    />
                    <textarea
                        className={`input-field ${encryptSecretError ? 'error' : ''}`}
                        placeholder="Secret (at least 8 characters)"
                        value={secret}
                        onChange={(e) => {
                            setSecret(e.target.value);
                            setEncryptSecretError(false);
                        }}
                    />
                    {encryptSecretError && (
                        <p className="error-text">Secret must be at least 8 characters</p>
                    )}
                </div>
                <div className="button-container">
                    <button className="button" onClick={handleEncrypt}>
                        Encrypt
                    </button>
                </div>
                <div className="output-box">
                    <p>Encrypted Value:</p>
                    <div className="input-container">
                        <textarea
                            readOnly
                            className="input-field"
                            value={encryptedValue}
                            onChange={(e) => setEncryptedValue(e.target.value)}
                        />
                    </div>
                </div>
            </div>

            <div className="jasypt-encryption decryption">
                {/* Decryption UI */}
                <div className="input-container">
                    <textarea
                        className="input-field"
                        placeholder="Encrypted Value"
                        value={encryptedValue}
                        onChange={(e) => setEncryptedValue(e.target.value)}
                    />
                    <textarea
                        className={`input-field ${decryptSecretError ? 'error' : ''}`}
                        placeholder="Secret (at least 8 characters)"
                        value={secret}
                        onChange={(e) => {
                            setSecret(e.target.value);
                            setDecryptSecretError(false);
                        }}
                    />
                    {decryptSecretError && (
                        <p className="error-text">Secret must be at least 8 characters</p>
                    )}
                </div>
                <div className="button-container">
                    <button className="button" onClick={handleDecrypt}>
                        Decrypt
                    </button>
                </div>
                <div className="output-box">
                    <p>Decrypted Value:</p>
                    <div className="input-container">
                        <textarea
                            readOnly
                            className="input-field"
                            value={decryptedValue}
                            onChange={(e) => setDecryptedValue(e.target.value)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JasyptEncryption;

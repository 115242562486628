/* tslint:disable */
/* eslint-disable */
/**
 * OIDC
 * OIDC Authorization for exchanging callback codes to JWT and validation of generated JWTs.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ExchangeCodeForOidcJwtsDto,
  IdentityProviderDto,
  OidcJwtsDto,
} from '../index';
import {
    ExchangeCodeForOidcJwtsDtoFromJSON,
    ExchangeCodeForOidcJwtsDtoToJSON,
    IdentityProviderDtoFromJSON,
    IdentityProviderDtoToJSON,
    OidcJwtsDtoFromJSON,
    OidcJwtsDtoToJSON,
} from '../index';

export interface ExchangeCodeForOidcJwtsRequest {
    identityProvider: IdentityProviderDto;
    exchangeCodeForOidcJwtsDto: ExchangeCodeForOidcJwtsDto;
}

/**
 * 
 */
export class OidcExchangeCodeForJwtsApi extends runtime.BaseAPI {

    /**
     * Exchange an Identity Provider\'s OAuth2 Authorization Code for a DTO containing self-signed ID, access, and refresh token JWTs.
     */
    async exchangeCodeForOidcJwtsRaw(requestParameters: ExchangeCodeForOidcJwtsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OidcJwtsDto>> {
        if (requestParameters.identityProvider === null || requestParameters.identityProvider === undefined) {
            throw new runtime.RequiredError('identityProvider','Required parameter requestParameters.identityProvider was null or undefined when calling exchangeCodeForOidcJwts.');
        }

        if (requestParameters.exchangeCodeForOidcJwtsDto === null || requestParameters.exchangeCodeForOidcJwtsDto === undefined) {
            throw new runtime.RequiredError('exchangeCodeForOidcJwtsDto','Required parameter requestParameters.exchangeCodeForOidcJwtsDto was null or undefined when calling exchangeCodeForOidcJwts.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/oidc/exchangeCodeForJwts/{identityProvider}`.replace(`{${"identityProvider"}}`, encodeURIComponent(String(requestParameters.identityProvider))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExchangeCodeForOidcJwtsDtoToJSON(requestParameters.exchangeCodeForOidcJwtsDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OidcJwtsDtoFromJSON(jsonValue));
    }

    /**
     * Exchange an Identity Provider\'s OAuth2 Authorization Code for a DTO containing self-signed ID, access, and refresh token JWTs.
     */
    async exchangeCodeForOidcJwts(requestParameters: ExchangeCodeForOidcJwtsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OidcJwtsDto> {
        const response = await this.exchangeCodeForOidcJwtsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/* tslint:disable */
/* eslint-disable */
/**
 * Jasypt Api
 * Jasypt Encryption & Decryption
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DecryptRequest,
  ResultDto,
} from '../index';
import {
    DecryptRequestFromJSON,
    DecryptRequestToJSON,
    ResultDtoFromJSON,
    ResultDtoToJSON,
} from '../index';

export interface JasyptDecryptRequest {
    decryptRequest: DecryptRequest;
}

/**
 * 
 */
export class JasyptDecryptApi extends runtime.BaseAPI {

    /**
     * Decrypt a value
     */
    async jasyptDecryptRaw(requestParameters: JasyptDecryptRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResultDto>> {
        if (requestParameters.decryptRequest === null || requestParameters.decryptRequest === undefined) {
            throw new runtime.RequiredError('decryptRequest','Required parameter requestParameters.decryptRequest was null or undefined when calling jasyptDecrypt.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;

            if (token) {
                headerParameters["Authorization"] = `Bearer ${token}`;
            }
        }

        const response = await this.request({
            path: `/jasypt/decrypt`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DecryptRequestToJSON(requestParameters.decryptRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResultDtoFromJSON(jsonValue));
    }

    /**
     * Decrypt a value
     */
    async jasyptDecrypt(requestParameters: JasyptDecryptRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResultDto> {
        const response = await this.jasyptDecryptRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

import React, {useEffect, useState} from 'react';
import queryString from 'query-string';
import {useNavigate} from 'react-router-dom';
import '../css/Login.css';
import googleIcon from '../images/google-icon.png'; // Import your Google icon image
import {OidcExchangeCodeForJwtsApi, IdentityProviderDto} from '../apis';
import {Configuration} from "../apis/runtime";

interface Props {
    setIdTokenJwt: (idTokenJwt: string) => void;
    setAccessTokenJwt: (accessTokenJwt: string) => void;
    setRefreshTokenJwt: (refreshTokenJwt: string) => void;
}

function Login({setIdTokenJwt, setAccessTokenJwt, setRefreshTokenJwt}: Props) {
    const [provider, setProvider] = useState<string | null>(null);
    const navigate = useNavigate();
    const authorizationServerUrl = process.env.REACT_APP_AUTHORIZATION_SERVER_URL;

    interface OAuth2Config {
        authUrl: string;
        tokenUrl: string;
        clientId: string;
        scope: string;
    }

    const oauth2Configs: Record<string, OAuth2Config> = {
        google: {
            authUrl: "https://accounts.google.com/o/oauth2/v2/auth",
            tokenUrl: "https://oauth2.googleapis.com/token",
            clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID!,
            scope: "profile email"
        },
        github: {
            authUrl: "https://github.com/login/oauth/authorize",
            tokenUrl: "https://github.com/login/oauth/access_token",
            clientId: process.env.REACT_APP_GITHUB_CLIENT_ID!,
            scope: "FIX"
        },
        facebook: {
            authUrl: "https://www.facebook.com/v12.0/dialog/oauth",
            tokenUrl: "https://graph.facebook.com/v12.0/oauth/access_token",
            clientId: process.env.REACT_APP_FACEBOOK_CLIENT_ID!,
            scope: "FIX"
        }
    };

    const handleLogin = (provider: string) => {
        setProvider(provider);
        const config = oauth2Configs[provider];
        const redirectUri = `${window.location.origin}/oauth2/callback/${provider}`;
        const authUrl = `${config.authUrl}?client_id=${config.clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&scope=${config.scope}&response_type=code`;
        window.location.href = authUrl;
    };

    useEffect(() => {
        const parsed = queryString.parse(window.location.search);
        const code = parsed.code as string;
        const providerFromURL = window.location.pathname.split("/").pop();
        let identityProviderEnumValue;

        switch (providerFromURL?.toLowerCase()) {
            case 'google':
                identityProviderEnumValue = IdentityProviderDto.Google;
                break;
            case 'github':
                identityProviderEnumValue = IdentityProviderDto.Github;
                break;
            case 'facebook':
                identityProviderEnumValue = IdentityProviderDto.Facebook;
                break;
            default:
                console.error("Unknown provider");
                return;
        }

        if (code && providerFromURL) {
            const oidc2AuthorizationReactiveInvestorBasePath = new Configuration({basePath: authorizationServerUrl});
            const oidcExchangeCodeForJwtsApi = new OidcExchangeCodeForJwtsApi(oidc2AuthorizationReactiveInvestorBasePath);
            const exchangeCodeForJwtsRequest = {
                identityProvider: identityProviderEnumValue,
                exchangeCodeForOidcJwtsDto: {
                    clientBaseUrl: window.location.protocol + '//' + window.location.host,
                    code: code,
                },
            };

            oidcExchangeCodeForJwtsApi.exchangeCodeForOidcJwts(exchangeCodeForJwtsRequest)
                .then(response => {
                    const idTokenJwt = response.idTokenJwt.jwt;
                    const accessTokenJwt = response.accessTokenJwt.jwt;
                    const refreshTokenJwt = response.refreshTokenJwt.jwt;
                    if (idTokenJwt && accessTokenJwt && refreshTokenJwt) {
                        setIdTokenJwt(idTokenJwt);
                        setAccessTokenJwt(accessTokenJwt);
                        setRefreshTokenJwt(refreshTokenJwt);
                        navigate('/');
                    } else {
                        console.error("One or more tokens are undefined");
                    }

                    console.log(idTokenJwt)
                    console.log(accessTokenJwt)
                    console.log(refreshTokenJwt)
                    console.log(`User authenticated: ${idTokenJwt}`);
            })
                .catch(error => {
                console.log("An error occurred during JWT token exchange:", error);
            });
        }
    }, [provider, setIdTokenJwt, setAccessTokenJwt, setRefreshTokenJwt, authorizationServerUrl, navigate]);

    return (
        <div className="login-button-container">
            <button className="login-button" onClick={() => handleLogin('google')}>
                <img src={googleIcon} alt="Google Icon" className="google-icon"/>
                <span>Login with Google</span>
            </button>
        </div>
    );
}

export default Login;
/* tslint:disable */
/* eslint-disable */
/**
 * Jasypt Api
 * Jasypt Encryption & Decryption
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  EncryptRequest,
  ResultDto,
} from '../index';
import {
    EncryptRequestFromJSON,
    EncryptRequestToJSON,
    ResultDtoFromJSON,
    ResultDtoToJSON,
} from '../index';

export interface JasyptEncryptRequest {
    encryptRequest: EncryptRequest;
}

/**
 * 
 */
export class JasyptEncryptApi extends runtime.BaseAPI {

    /**
     * Encrypt a value
     */
    async jasyptEncryptRaw(requestParameters: JasyptEncryptRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResultDto>> {
        if (requestParameters.encryptRequest === null || requestParameters.encryptRequest === undefined) {
            throw new runtime.RequiredError('encryptRequest','Required parameter requestParameters.encryptRequest was null or undefined when calling jasyptEncrypt.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;

            if (token) {
                headerParameters["Authorization"] = `Bearer ${token}`;
            }
        }
        const response = await this.request({
            path: `/jasypt/encrypt`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EncryptRequestToJSON(requestParameters.encryptRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResultDtoFromJSON(jsonValue));
    }

    /**
     * Encrypt a value
     */
    async jasyptEncrypt(requestParameters: JasyptEncryptRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResultDto> {
        const response = await this.jasyptEncryptRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/* tslint:disable */
/* eslint-disable */
/**
 * OIDC
 * OIDC Authorization for exchanging callback codes to JWT and validation of generated JWTs.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The OIDC Identity Provider (e.g., GOOGLE, GITHUB, FACEBOOK).
 * @export
 */
export const IdentityProviderDto = {
    Google: 'GOOGLE',
    Github: 'GITHUB',
    Facebook: 'FACEBOOK'
} as const;
export type IdentityProviderDto = typeof IdentityProviderDto[keyof typeof IdentityProviderDto];


export function IdentityProviderDtoFromJSON(json: any): IdentityProviderDto {
    return IdentityProviderDtoFromJSONTyped(json, false);
}

export function IdentityProviderDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): IdentityProviderDto {
    return json as IdentityProviderDto;
}

export function IdentityProviderDtoToJSON(value?: IdentityProviderDto | null): any {
    return value as any;
}


import React from 'react';
import PropTypes from 'prop-types';

interface NavigationBarProps {
    handleLogout: () => void;
}

const NavigationBar: React.FC<NavigationBarProps> = ({ handleLogout }) => {
    return (
        <div className="navbar" style={{ position: 'absolute', top: 0, right: 0 }}>
            <button className="logout-button" onClick={handleLogout}>
                Logout
            </button>
            {/* Add other navigation links as needed */}
        </div>
    );
};

NavigationBar.propTypes = {
    handleLogout: PropTypes.func.isRequired,
};

export default NavigationBar;

/* tslint:disable */
/* eslint-disable */
/**
 * OIDC
 * OIDC Authorization for exchanging callback codes to JWT and validation of generated JWTs.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
/**
 * 
 * @export
 * @interface ExchangeCodeForOidcJwtsDto
 */
export interface ExchangeCodeForOidcJwtsDto {
    /**
     * The client's base URL, which is the same as the OIDC Identity Provider callback URI.
     * @type {string}
     * @memberof ExchangeCodeForOidcJwtsDto
     */
    clientBaseUrl: string;
    /**
     * The OIDC authorization code received from the Identity Provider.
     * @type {string}
     * @memberof ExchangeCodeForOidcJwtsDto
     */
    code: string;
}

/**
 * Check if a given object implements the ExchangeCodeForOidcJwtsDto interface.
 */
export function instanceOfExchangeCodeForOidcJwtsDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "clientBaseUrl" in value;
    isInstance = isInstance && "code" in value;

    return isInstance;
}

export function ExchangeCodeForOidcJwtsDtoFromJSON(json: any): ExchangeCodeForOidcJwtsDto {
    return ExchangeCodeForOidcJwtsDtoFromJSONTyped(json, false);
}

export function ExchangeCodeForOidcJwtsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExchangeCodeForOidcJwtsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clientBaseUrl': json['clientBaseUrl'],
        'code': json['code'],
    };
}

export function ExchangeCodeForOidcJwtsDtoToJSON(value?: ExchangeCodeForOidcJwtsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'clientBaseUrl': value.clientBaseUrl,
        'code': value.code,
    };
}


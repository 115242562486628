/* tslint:disable */
/* eslint-disable */
/**
 * OIDC
 * OIDC Authorization for exchanging callback codes to JWT and validation of generated JWTs.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { JwtDto } from './JwtDto';
import {
    JwtDtoFromJSON,
    JwtDtoFromJSONTyped,
    JwtDtoToJSON,
} from './JwtDto';

/**
 * DTO object containing OIDC JWTs, such as id, access, and refresh tokens.
 * @export
 * @interface OidcJwtsDto
 */
export interface OidcJwtsDto {
    /**
     * 
     * @type {JwtDto}
     * @memberof OidcJwtsDto
     */
    idTokenJwt: JwtDto;
    /**
     * 
     * @type {JwtDto}
     * @memberof OidcJwtsDto
     */
    accessTokenJwt: JwtDto;
    /**
     * 
     * @type {JwtDto}
     * @memberof OidcJwtsDto
     */
    refreshTokenJwt: JwtDto;
}

/**
 * Check if a given object implements the OidcJwtsDto interface.
 */
export function instanceOfOidcJwtsDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "idTokenJwt" in value;
    isInstance = isInstance && "accessTokenJwt" in value;
    isInstance = isInstance && "refreshTokenJwt" in value;

    return isInstance;
}

export function OidcJwtsDtoFromJSON(json: any): OidcJwtsDto {
    return OidcJwtsDtoFromJSONTyped(json, false);
}

export function OidcJwtsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OidcJwtsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idTokenJwt': JwtDtoFromJSON(json['idTokenJwt']),
        'accessTokenJwt': JwtDtoFromJSON(json['accessTokenJwt']),
        'refreshTokenJwt': JwtDtoFromJSON(json['refreshTokenJwt']),
    };
}

export function OidcJwtsDtoToJSON(value?: OidcJwtsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idTokenJwt': JwtDtoToJSON(value.idTokenJwt),
        'accessTokenJwt': JwtDtoToJSON(value.accessTokenJwt),
        'refreshTokenJwt': JwtDtoToJSON(value.refreshTokenJwt),
    };
}


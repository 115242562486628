/* tslint:disable */
/* eslint-disable */
/**
 * OIDC
 * OIDC Authorization for exchanging callback codes to JWT and validation of generated JWTs.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
/**
 * A standard JWT in the format of an id, access, or refresh token.
 * @export
 * @interface JwtDto
 */
export interface JwtDto {
    /**
     * A standard JWT
     * @type {string}
     * @memberof JwtDto
     */
    jwt?: string;
}

/**
 * Check if a given object implements the JwtDto interface.
 */
export function instanceOfJwtDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JwtDtoFromJSON(json: any): JwtDto {
    return JwtDtoFromJSONTyped(json, false);
}

export function JwtDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JwtDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'jwt': !exists(json, 'jwt') ? undefined : json['jwt'],
    };
}

export function JwtDtoToJSON(value?: JwtDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'jwt': value.jwt,
    };
}


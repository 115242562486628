/* tslint:disable */
/* eslint-disable */
/**
 * Jasypt Api
 * Jasypt Encryption & Decryption
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
/**
 * 
 * @export
 * @interface ResultDto
 */
export interface ResultDto {
    /**
     * 
     * @type {string}
     * @memberof ResultDto
     */
    value: string;
}

/**
 * Check if a given object implements the ResultDto interface.
 */
export function instanceOfResultDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "value" in value;

    return isInstance;
}

export function ResultDtoFromJSON(json: any): ResultDto {
    return ResultDtoFromJSONTyped(json, false);
}

export function ResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResultDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': json['value'],
    };
}

export function ResultDtoToJSON(value?: ResultDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
    };
}

